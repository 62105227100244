import React, { useState } from "react";
import financialGrowthIllustration from "../../images/financial_growth.svg";
import enquiryIllustration from "../../images/enquiry_form.svg";
import SwyftLogo from "../SwyftLogo";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialLogo,
  SocialClaim,
  FormSection,
  FormContainer,
  FormContent,
  FormTitle,
  FormDescription,
  StyledForm,
  InputWrapper,
  StyledLabel,
  StyledInput,
  StyledTextarea,
  SubmitButton,
  IllustrationContainer,
  Illustration,
  TabContainer,
  TabButton,
} from "./FooterElements";

const handleSubmit = (e) => {
  e.preventDefault();
  // Form sending logic here
};

const Footer = ({id}) => {
  const [activeTab, setActiveTab] = useState("partner");
  return (
    <FooterContainer id={id}>
      <FormSection>
        <FormContainer>
          <FormContent>
            <TabContainer>
              <TabButton
                active={activeTab === "partner"}
                onClick={() => setActiveTab("partner")}
              >
                Become a Partner
              </TabButton>
              <TabButton
                active={activeTab === "enquiry"}
                onClick={() => setActiveTab("enquiry")}
              >
                Enquiry Form
              </TabButton>
            </TabContainer>
            <FormTitle>
              {activeTab === "partner"
                ? "Become a Swyft Partner™ today."
                : "Looking for finance? Enquire now."}
            </FormTitle>
            <FormDescription>
              {activeTab === "partner"
                ? "Working to improve your client's financial position is an endeavour that requires as many tools as possible. Finding the right leverage options can become increasingly difficult and having access to an opinion in smaller ticket financing may just be the answer."
                : "Have a question or need more information? Fill out the form below and we'll get back to you as soon as possible."}
            </FormDescription>
            <StyledForm onSubmit={handleSubmit}>
              {activeTab === "partner" ? (
                <>
                  <InputWrapper>
                    <StyledLabel htmlFor="partnerName">
                      Company Name
                    </StyledLabel>
                    <StyledInput id="partnerName" type="text" required />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel htmlFor="partnerEmail">
                      Business Email
                    </StyledLabel>
                    <StyledInput id="partnerEmail" type="email" required />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel htmlFor="partnerPhone">
                      Phone Number
                    </StyledLabel>
                    <StyledInput id="partnerPhone" type="tel" required />
                  </InputWrapper>
                </>
              ) : (
                <>
                  <InputWrapper>
                    <StyledLabel htmlFor="enquiryName">Name</StyledLabel>
                    <StyledInput id="enquiryName" type="text" required />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel htmlFor="enquiryEmail">Email</StyledLabel>
                    <StyledInput id="enquiryEmail" type="email" required />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel htmlFor="enquiryMobile">Mobile</StyledLabel>
                    <StyledInput id="enquiryMobile" type="tel" required />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel htmlFor="enquiryDescription">Description</StyledLabel>
                    <StyledTextarea
                      id="enquiryDescription"
                      placeholder="Write your description..."
                      required
                    />
                  </InputWrapper>
                </>
              )}
              <InputWrapper>
                <SubmitButton type="submit">
                  {activeTab === "partner" ? "Contact Us" : "Send Enquiry"}
                </SubmitButton>
              </InputWrapper>
            </StyledForm>
          </FormContent>
          <IllustrationContainer>
            <Illustration
              src={activeTab === "partner" ? financialGrowthIllustration : enquiryIllustration}
              alt={
                activeTab === "partner"
                  ? "Financial growth illustration"
                  : "Enquiry illustration"
              }
            />
          </IllustrationContainer>
        </FormContainer>
      </FormSection>
      <FooterWrap>
        <SocialMedia>
          <SocialLogo to="/">
            <SwyftLogo H={48.8} W={100} />
          </SocialLogo>
          <SocialClaim>
            SPG Capital Pty Ltd trading as Swyft Finance Partners ABN 80 652 504
            599 are Credit Representatives (CRN: 537077) under Connective Credit
            Services Australian Credit License (ACL: 389328).
          </SocialClaim>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
