import React, { useState } from "react";
import styled from "styled-components";

import SwyftLogo from "../images/swyftShadow3.svg";
import { Handshake, Store } from "lucide-react";

const SignInContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  min-height: 100vh;
  background-color: #4e8395;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const LeftSection = styled.div`
  position: relative;
  min-height: 300px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  filter: brightness(0.7);
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  color: #255565;
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;

  @media (max-width: 480px) {
    padding: 10px;
    font-size: 0.9rem;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: #f7954e;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e78339;
  }

  @media (max-width: 480px) {
    padding: 10px;
    font-size: 0.9rem;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
`;

const SwitchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 20px;
  background-color: ${(props) => (props.active ? "#4e8395" : "#ffffff")};
  color: ${(props) => (props.active ? "#ffffff" : "#255565")};
  border: 1px solid #4e8395;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 1;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
`;

const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50%;
  max-width: 400px;
`;

const SignInPage = () => {
  const [isCustomer, setIsCustomer] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle sign-in logic here
  };

  return (
    <SignInContainer>
      <LeftSection>
        <VideoBg autoPlay playsInline loop muted src={require("../videos/video1.mp4")} type="video/mp4" />
        <Logo src={SwyftLogo} alt="Company Logo" />
      </LeftSection>
      <FormSection>
        <FormContainer>
          <Title>Sign In</Title>
          <SwitchContainer>
            <SwitchButton
              type="button"
              active={isCustomer}
              onClick={() => setIsCustomer(true)}
            >
              <Store size={15} />
              Customer
            </SwitchButton>
            <SwitchButton
              type="button"
              active={!isCustomer}
              onClick={() => setIsCustomer(false)}
            >
              <Handshake size={15} />
              Partner
            </SwitchButton>
          </SwitchContainer>
          <form onSubmit={handleSubmit}>
            <Input type="email" placeholder="Email" required />
            <Input type="password" placeholder="Password" required />
            <SubmitButton type="submit">
              Sign In as {isCustomer ? "Customer" : "Partner"}
            </SubmitButton>
          </form>
        </FormContainer>
      </FormSection>
    </SignInContainer>
  );
};

export default SignInPage;
